const quoteArray = [
    {
        id: 1,
        author: null,
        title: null,
        quote: "Every city should have a Sourcing 7. The fact that people volunteer their time and speakers come and share thoughts and knowledge to make the community is simply awesome. #payitforward"
    },
    {
        id: 2,
        author: "Doug Berg", 
        title: "ZAPInfo CEO",
        quote: "Your group is easily one of the best organized and deep thinking groups I’ve ever seen."
    },
    {
        id: 3,
        author: "Sherie",
        title: "Non-Profit Board Director",
        quote: "Each time I attend a Sourcing 7 event, I come away with new ideas, techniques and tools to source great talent for my organization. Many thanks to the Sourcing 7 team for all of the planning that goes into making these events so valuable."
    },
    {
        id: 4,
        author: "Arzu",
        title: "Search Consultant",
        quote: "Participating in the events exposed me to a lot of new sourcing tools and techniques, and I was able to meet great recruiters in Seattle which I consider very important to someone new in the area."
    },
    {
        id: 5,
        author: "Tim",
        title: "Recruiting Director",
        quote: "We're all in this together so being a part of this great group of talented individuals only helps you feel like you're never alone."        
    },
    {
        id: 6,
        author: "Carmen",
        title: "Co-founder and Principal Consultant",
        quote: "Sourcers and recruiters in Seattle are fortunate to have this resource for learning and sharing!"
    },
    {
        id: 7,
        author: null,
        title: null,
        quote: "I highly recommend Sourcing7 to sourcers and recruiters in the Puget Sound area based on events I've attended and subsequent interactions with members. This group offers cutting edge content and the opportunity to network with leading industry professionals. The group is welcoming and accessible."
    },
    {
        id: 8,
        author: "Lynn",
        title: "Recruiting Manager",
        quote: "In the world of professional networking groups, Sourcing 7 is the top of the cake. Events and topics are useful and highly organized. Everyone is friendly, open and passionate. It's fun to talk shop and learn new information and techniques. This is a good use of time for anyone in the recruiting and sourcing community."
    },
    {
        id: 9,
        author: "Sheetal",
        title: "Operations Director",
        quote: "Sourcing 7 has been my greatest resource and foundation for professional networking in person with recruiting leaders in our region."
    },
    {
        id: 10,
        author: "Jenn",
        title: "Talent Advisor",
        quote: "When I first started in this industry, I tried to attend all the events to learn and network. 7+ years later and I still love attending to meet other peers and keep my knowledge base fresh!"
    }
]

export default quoteArray;