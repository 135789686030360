const pastLeadersArray = [
    {
        name: "Amybeth Quinn",
        title: null,
        imgURL: "amybeth-quinn.webp",
        linkedinURL: null
    },
    {
        name: "Dean DaCosta",
        title: null,
        imgURL: "dean-dacosta.webp",
        linkedinURL: null
    },
    {
        name: "Kay Kellison",
        title: "Founding Member",
        imgURL: "kay-kellison.webp",
        linkedinURL: null
    },
    {
        name: "Brian Reichart",
        title: "Founding Member",
        imgURL: "brian-reichart.webp",
        linkedinURL: null
    },
    {
        name: "Tito Magobet",
        title: "Founding Member",
        imgURL: "tito-magobet.webp",
        linkedinURL: null
    },
    {
        name: "Michael Michaels",
        title: null,
        imgURL: "michael-michaels.webp",
        linkedinURL: null
    },
    {
        name: "James Temple",
        title: null,
        imgURL: "james-temple.webp",
        linkedinURL: null
    },
    {
        name: "Sara Fleischman",
        title: null,
        imgURL: "sara-fleischman.webp",
        linkedinURL: null
    },
    {
        name: "Jessica Mango",
        title: null,
        imgURL: "jessica-mango.webp",
        linkedinURL: null
    },
    {
        name: "Teresa Colquitt",
        title: null,
        imgURL: "teresa-colquitt.webp",
        linkedinURL: null
    },
    {
        name: "Scott Shay",
        title: null,
        imgURL: "scott-shay.webp",
        linkedinURL: null
    },
    {
        name: "Mai Lu",
        title: null,
        imgURL: "mai-lu.webp",
        linkedinURL: null
    },
]

export default pastLeadersArray;