import React from "react";
import { Link } from "react-router-dom";
import Socials from "./Socials";

export default function Footer() {
    return(
        <footer>
            <Socials />
            <p className="lg-font brand-red bold">www.sourcing7.org</p>
            <p className="lg-font brand-red bold underline"><Link to="https://www.linkedin.com/in/the-recruiter-that-is-technical/" target="_blank" rel="noreferrer">Developer & Site Manager</Link></p>
        </footer>
    )
}