const leadershipArray = [
    {
        name: "Todd Davis",
        title: "Board Chair",
        imgURL: "todd-davis.webp",
        linkedinURL: "https://www.linkedin.com/in/todd-b-davis/"
    },
    {
        name: "Kara Baskett",
        title: "Board Director",
        focus: "Governance",
        imgURL: "kara-baskett.webp",
        linkedinURL: "https://www.linkedin.com/in/kbaskett/"
    },
    {
        name: "Mariah Watson",
        title: "Board Director",
        focus: "Events",
        imgURL: "mariah-watson.jpg",
        linkedinURL: "https://www.linkedin.com/in/mariah-watson-bb188aba/"
    },
    {
        name: "Marvin Smith",
        title: "Board Director",
        focus: "Community Partnerships",
        imgURL: "marv-smith.jpg",
        linkedinURL: "https://www.linkedin.com/in/marvsmith/"
    },
    {
        name: "Andy Gaudy",
        title: "Board Director",
        focus: "Technology",
        imgURL: "andy-gaudy.jpg",
        linkedinURL: "https://www.linkedin.com/in/the-recruiter-that-is-technical/"
    },
    {
        name: "Phil Hendrickson",
        title: "Board Director",
        focus: "Fundraising",
        imgURL: "phil-hendrickson.webp",
        linkedinURL: "https://www.linkedin.com/in/philhendrickson/"
    },
    {
        name: "Adam Kovacs",
        title: "Executive Director & Vice Chair of the Board",
        imgURL: "adam-kovacs.jpg",
        linkedinURL: "https://www.linkedin.com/in/adambkovacs/"
    },
    {
        name: "Klara Hermesz",
        title: "Board Director",
        focus: "Education & Community Learning",
        imgURL: "klara-hermesz.jpg",
        linkedinURL: "https://www.linkedin.com/in/klarahermesz/"
    },
    {
        name: "Jer Langhans",
        title: "Advisor",
        imgURL: "jeremy-langhans.webp",
        linkedinURL: "https://www.linkedin.com/in/jerlanghans/"
    },
    {
        name: "Michelle Taylor",
        title: "Advisor",
        imgURL: "michelle-taylor.webp",
        linkedinURL: "https://www.linkedin.com/in/geoarks/"
    },
    {
        name: "Dee Waluk-Johnson",
        title: "Advisor",
        imgURL: "dee-waluk-johnson.jpg",
        linkedinURL: "https://www.linkedin.com/in/deewaluk/"
    },
    {
        name: "Roger Wagoner",
        title: "Volunteer",
        imgURL: "roger-wagoner.webp",
        linkedinURL: "https://www.linkedin.com/in/rogerwagoner1/"
    },
    {
        name: "Lukas MacMillen",
        title: "Volunteer",
        focus: "Data Engineering",
        imgURL: "lukas-macmillen.jpg",
        linkedinURL: "https://www.linkedin.com/in/lukas-macmillen/"
    },
]



export default leadershipArray