import React from "react";

export default function Ambassador() {
    return(
        <div className="ambassador darken-bg">
            <h3 className="subtitle">Ambassador Program</h3>
            <hr className="separator " style={{ backgroundColor: "white"}}/>
            <p className="information-text">Believing in the idea of “Paying it Forward,” we’re launching the Sourcing7+ Ambassador Program soon. We've asked local community members from some of the area's employers to help with event promotions and encouraging colleagues and teammates to participate in events throughout the year.  We aim to provide opportunities for Ambassadors to grow professionally while increasing outreach to the local community and encouraging networking and relationship development outside of our events.</p>
            <br />
            <p className="information-text">Sourcing7+ Ambassadors are  individuals in the community who believe in the mission of Sourcing7+, which is to foster opportunities to network, influence, and educate. As company representatives, Ambassadors help promote Sourcing7+ internally to their teams and encourage teammates to attend, present, and/or volunteer.  As community members, Ambassadors encourage others around them in collaboration, networking,  and sharing of best practices.</p>
        </div>
    )
}