// import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom";
import s7logo from "../assets/s7-logo.png";
import UpcomingEvent from "./UpcomingEvent";
import Quotes from "./Quotes";
import Leadership from "./Leadership";
import Events from "./Events";
import Sponsors from "./Sponsors";
import Ambassador from "./Ambassador";

export default function Home( {width, setWidth} ) {

    const upcomingEvent = false;

    return (
        <>
            {/* fixed backsplash */}
            <div className="backsplash"></div>
            { upcomingEvent && <UpcomingEvent upcomingEvent={upcomingEvent}/>}
            {/* Conditionally applies #about to control the auto-scroll behavior 
                If there is an upcomingEvent, #about is applied to it so the page 
                scrolls correctly*/}
            <div id={!upcomingEvent ? "about" : ""} className="landing-intro">
                <img className="intro-logo" src={s7logo} alt="sourcing 7 design" />
                <h2 className="brand-red subtitle">NETWORK | INFLUENCE | EDUCATE</h2>
                <p className="prominent-text">Sourcing7+ is a non-profit professional organization dedicated to talent sourcing in the Pacific Northwest. We support anyone who identifies as a talent sourcing, research, or recruiting professional in the pursuit of career excellence.</p>
                <p className="information-text">As the S7+ name suggests, we’re more than just sourcers! The Sourcing7+ community is comprised of sourcers, recruiters, talent acquisition leaders and other TA professionals who share best practices, exchange techniques, and enhance the talent sourcing profession. The S7+ community was born from the grassroots effort of talent sourcers who wanted to pay it forward for this profession. We hope to accomplish this through fostering opportunities to network, influence, and educate.</p>
                <p className="bold">Stay up-to-date on events and opportunities to participate:</p>
                <Link to="https://sourcing7.us14.list-manage.com/subscribe?u=4741d465c333382662823da67&id=e0110e10f0" target="_blank" rel="noreferrer"><button className="mail-list-button">Join S7+ Mailing List</button></Link>
            </div>
            <Quotes />
            <Leadership width={width}/>
            <Events upcomingEvent={upcomingEvent} />
            <Sponsors />
            <Ambassador />
        </>
    )
}