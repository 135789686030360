import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Home";

function App() {
    const [width, setWidth] = useState(window.innerWidth);
    const [mobile, setMobile] = useState(width <= 785 ? true : false);
    const [mobileNavIsShown, setMobileNavIsShown] = useState(false);
    const [modalIsShown, setModalIsShown] = useState(false);

    const setInnerWidth = () => {
        setWidth(window.innerWidth);
        if (window.innerWidth <= 785) {
            setMobile(true);
        } else setMobile(false);
    };

    useEffect(() => {
        window.addEventListener("resize", setInnerWidth);
        return () => {
            window.removeEventListener("resize", setInnerWidth);
        };
    }, []);

    // JS to target all anchor tags with # hrefs and remove their default behavior (i.e. updating the URL in the browser)
    // Also applying smooth scrolling behavior
    useEffect(() => {
        document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
            anchor.addEventListener("click", function (event) {
                event.preventDefault();

                const target = document.querySelector(
                    this.getAttribute("href")
                );
                if (target) {
                    target.scrollIntoView({
                        behavior: "smooth",
                    });
                }
            });
        });
    }, [mobile]);

    // If mobile state is true (width < 765), shrink mobile height to 75px by setting
    // --header-height in components.css root
    useEffect(() => {
        if (mobile) {
            document.documentElement.style.setProperty(
                "--header-height",
                "75px"
            );
        } else
            document.documentElement.style.setProperty(
                "--header-height",
                "150px"
            );
    }, [mobile]);

    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <Layout
                                width={width}
                                setWidth={setWidth}
                                mobileNavIsShown={mobileNavIsShown}
                                setMobileNavIsShown={setMobileNavIsShown}
                                modalIsShown={modalIsShown}
                                setModalIsShown={setModalIsShown}
                                mobile={mobile}
                            />
                        }
                    >
                        <Route
                            index
                            element={<Home width={width} setWidth={setWidth} />}
                        />
                    </Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
