import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";

export default function Layout( {width, setWidth, mobileNavIsShown, setMobileNavIsShown, modalIsShown, setModalIsShown, mobile} ) {
    
    return(
        <main>
            <Header width={width} setWidth={setWidth} mobileNavIsShown={mobileNavIsShown} setMobileNavIsShown={setMobileNavIsShown} modalIsShown={modalIsShown} setModalIsShown={setModalIsShown} mobile={mobile}/>
            <Outlet />
            <Footer width={width} setWidth={setWidth}/>
        </main>
    )
}