import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import s7logo from "../assets/s7-logo.png";
import "../styles/components.css";
import Socials from "./Socials";

export default function Header({width, setWidth, mobileNavIsShown, setMobileNavIsShown, modalIsShown, setModalIsShown, mobile}) {
    
    // onTouchStart and onTouchEnd functions to handle mobile menu clicking
    const handleTouchStart = (event) => {
        event.target.style.color = "lightgray";
    }

    const handleTouchEnd = (event) => {
        event.target.style.color = "white";
    }

    const handleClick = (event) => {
        window.location.href = "/";
    }

    // Toggles the value of state for showing the mobile nav menu. Mobile nav menu is controlled at App so Modal can also close it
    const showMobileNav = (event) => {
        setMobileNavIsShown(prevState => !prevState);
        setModalIsShown(prevState => !prevState)    
    }

    // When screen width and mobile state changes, checks to see if the mobile menu is open and if so, closes the modal and mobile menu
    useEffect( () => {
        if(mobileNavIsShown){
            setMobileNavIsShown(false);
            setModalIsShown(false);
        }
    }, [mobile])

    return (
        <header>
            {/* Need to rewrite a closeAll() utility function for modal */}
            <div className="modal" style={modalIsShown ? {display: "block"} : {display: "none"}} onClick={showMobileNav}></div>
            <div className={`logo-container ${mobile ? "mobile" : ""}`} onClick={handleClick} >
                <img id="header-logo" src={s7logo} alt="sourcing 7" />
            </div>
            <nav>
                {width > 785 ? (
                <div className="desktop-nav">
                    <ul className="nav-menu">
                        <a href="#about"><li className="nav-item" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>About</li></a>
                        <a href="#leadership"><li className="nav-item" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>Leadership</li></a>
                        <a href="#events"><li className="nav-item" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>Events</li></a>
                        <a href="#sponsors"><li className="nav-item" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>Sponsors & Hosts</li></a>
                        <Link to="https://sourcing7.us14.list-manage.com/subscribe?u=4741d465c333382662823da67&id=e0110e10f0" target="_blank" rel="noreferrer"><li className="nav-item" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>Join the Mailing List</li></Link>               
                    </ul>
                    <Socials />
                </div>
                )
                : (
                <div className="mobile-nav">
                    <svg className={`hamburger-button ${mobileNavIsShown ? 'x' : ''}`} viewBox="0 0 100 100" width="40" height="40" onClick={showMobileNav}>
                        <line x1="0" y1="20" x2="100" y2="20" stroke="white" strokeWidth="10"/>
                        <line x1="0" y1="50" x2="100" y2="50" stroke="white" strokeWidth="10"/>
                        <line x1="0" y1="80" x2="100" y2="80" stroke="white" strokeWidth="10"/>
                    </svg>
                    <div id="mobile-nav-menu" className="mobile-nav-menu" style={mobileNavIsShown ? {right: 0 + 'px'} : {right: -225 + 'px'}}>
                        <div className="mobile-nav-header"></div>
                        <ul>
                            <a href="#about"><li className="nav-item mobile" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} onClick={() => {setMobileNavIsShown(false); setModalIsShown(false)}}>About</li></a>
                            <a href="#leadership"><li className="nav-item mobile" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} onClick={() => {setMobileNavIsShown(false); setModalIsShown(false)}}>Leadership</li></a>
                            <a href="#events"><li className="nav-item mobile" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} onClick={() => {setMobileNavIsShown(false); setModalIsShown(false)}}>Events</li></a>
                            <a href="#sponsors"><li className="nav-item mobile" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} onClick={() => {setMobileNavIsShown(false); setModalIsShown(false)}}>Sponsors & Hosts</li></a>
                            <Link to="https://sourcing7.us14.list-manage.com/subscribe?u=4741d465c333382662823da67&id=e0110e10f0" target="_blank" rel="noreferrer"><li className="nav-item mobile" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} onClick={() => {setMobileNavIsShown(false); setModalIsShown(false)}}>Join the Mailing List</li></Link>                  
                        </ul>
                        <Socials />
                    </div>
                </div>
                )}
                
            </nav>
        </header>
    )
}