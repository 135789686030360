import React from "react";
import { Link } from "react-router-dom"
import linkedinLogo from "../assets/linkedin-logo.webp"
import twitterLogo from "../assets/twitter.webp"
import facebookLogo from "../assets/facebook.webp"
import emailLogo from "../assets/email.webp"

export default function Socials() {
    return(
        <div className="socials">
            <Link to="https://www.linkedin.com/company/sourcing7/" rel="noreferrer" target="_blank">
                <div className="social-logo">
                    <img src={linkedinLogo} alt="LinkedIn"/>
                </div>
            </Link>
            <Link to="https://twitter.com/Sourcing7" rel="noreferrer" target="_blank">
                <div className="social-logo">
                    <img src={twitterLogo} alt="Twitter"/>
                </div>
            </Link>
            <Link to="https://www.facebook.com/groups/sourcing7" rel="noreferrer" target="_blank">
                <div className="social-logo">
                    <img src={facebookLogo} alt="Facebook"/>
                </div>
            </Link>
            <Link to="mailto:info@sourcing7.com">
                <div className="social-logo">
                    <img src={emailLogo} alt="Email"/>
                </div>      
            </Link>
        </div>
    )
}