import React, {useState} from "react";
import { Link } from "react-router-dom"
// import originalS7photo from "../assets/profile-pics/original_s7.webp";
import s7logo from "../assets/s7-logo.png";
import pastLeadersArray from "../util/pastLeadersArray"
import currentLeadersArray from "../util/currentLeadersArray"

export default function Leadership({width}) {
    
    //ref to the object array containing leadership info
    const [pastLeaderArray, setPastLeaderArray] = useState(pastLeadersArray);
    
    //map over the array to create the leader elements
    const pastLeaders = pastLeaderArray.map( ( leader, index ) => {
        return(
            <div className="photo" key={`pastleader-${index}`}>
                <img src={process.env.PUBLIC_URL + `/profile-pics/past/${leader.imgURL}`} alt={leader.name} />
            </div>
        )
    })

    const currentLeaders = currentLeadersArray.map( ( leader, index ) => {
        
        // name: "Todd Davis",
        // title: "Board Chair",
        // imgURL: "todd-davis.webp",
        // linkedinURL: "https://www.linkedin.com/in/todd-b-davis/"

        return(
            <div className="leadership-card" key={`leader-${index}`}>
                <Link to={`${leader.linkedinURL}`} rel="noreferrer" target="_blank"><img src={process.env.PUBLIC_URL + `/profile-pics/current/${leader.imgURL}`} alt={leader.name} /></Link>
                <p className="leadership-name">{leader.name}</p>
                <p className="leadership-title">{leader.title}</p>
                {leader.focus ? <p className="leadership-focus">{leader.focus}</p> : ""}
                {/* {process.env.PUBLIC_URL + `/profile-pics/current/${leader.imgURL}`} */}
                {/* {process.env.PUBLIC_URL + `/profiles-pics/current/${leader.imgURL}`} */}
            </div>
        )
    } )

    return (
        <div id="leadership" className="leadership">
            <div className="current-leadership-team brand-red">
                <h3 className="subtitle">Sourcing7+ Leadership Team</h3>
                <hr className="separator brand-red" />
                <div className="current-leader-container">
                    {currentLeaders}
                </div>
                <p className="prominent-text">In 2009, seven Seattle-area talent sourcing professionals began meeting at a local Starbucks to share ideas and techniques and to discuss a relatively new discipline of talent sourcing. The Internet and social media had changed sourcing, and these seven professionals were determined to get their collective heads around new ways of thinking for talent sourcing in the digital age; thus, they became the Sourcing7+.</p>
                <p className="prominent-text">The team, which has always consisted of 100% volunteers, desire to give back to the local community. This team is made up of Recruiters, Sourcers, and Talent Acquisition experts lending their services to respected companies in the Pacific Northwest.</p>
            </div>
            
            <h3 className="subtitle">Sourcing7+ Leadership Alumni</h3>
            <hr className="separator" style={{backgroundColor: "black"}}/>
            <div className="past-leadership-team">
                <div className={width > 600 ? "left-past-leader-container" : "hidden"}>
                    <img className="original-s7-photo" src={process.env.PUBLIC_URL + "/profile-pics/past/original_s7.webp"} alt="Founding members of S7" />
                    <img className="original-s7-logo" src={s7logo} alt="Sourcing 7 logo" />
                </div>
                <aside className={ width > 600 ? "right-past-leader-container-grid" : "right-past-leader-container-flex"}>
                    {pastLeaders}
                </aside>
            </div>
        </div>
    )
}