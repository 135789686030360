import React from "react";
import { Link } from "react-router-dom";
import sponsorArray from "../util/sponsorArray";

export default function Sponsors() {
    
    // const sponsorCards = sponsorArray.map( (sponsor, index) => {
    //     return(
    //         <Link to={sponsor.websiteURL} rel="noreferrer" target="_blank" key={`sponsor-card-${index}`}>
    //             <div className="sponsor-info">
    //                 <img src={sponsor.logoURL} alt={sponsor.companyName}/>
    //                 <p className="sm-font italic">{sponsor.companyName}</p>
    //             </div>
    //         </Link>
    //     )
    // })

    return (
        <div id="sponsors" className="sponsors">
            <div className="sponsors-header">
                <h3 className="brand-red subtitle">Sponsors & Hosts</h3>
                <hr className="separator brand-red" />
                <p className="brand-red prominent-text">We wouldn't be able to offer free training workshops and events without the support and partnership of our sponsors and venue hosts.</p>
            </div>
            <p className="information-text">Sourcing7+ is a 501(c)(3) non-profit organization, and as such, relies on the generosity and support of our local community and global sponsorship partners to continue serving the talent acquisition community through free educational events and networking opportunities.</p>
            <p className="brand-red">If you're interested in sponsoring the community, <Link to="mailto:sponsorship@sourcing7.org?subject=S7+ Sponsorship Inquiry" className="underline">click here to get in touch for more information.</Link></p>
        </div>
    )
}