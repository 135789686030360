import React, {useState, useEffect, useRef} from "react"
import quoteArray from "../util/quoteArray"

export default function Quotes() {
    
    const [quoteIndex, setQuoteIndex] = useState(0);
    // state for landing on a random quote on each visit
    // const [quoteIndex, setQuoteIndex] = useState(Math.floor(Math.random() * quoteArray.length));
    const slideDuration = 7500;
    const [quoteState, setQuoteState] = useState(true);
    const intervalRef = useRef(null);

    // algorithm to start the quote timer
    // checks ref and only runs when interval is inactive
    const startQuoteInterval = () => {
        if(intervalRef.current) return;

        //if on the last quote index, setQuoteIndex to the beginning (0)
        //else increment the index + 1
        //runs based on slideDuration var set above
        intervalRef.current = setInterval(() => {
            setQuoteIndex( prevQuoteIndex => {
                if(prevQuoteIndex === quoteArray.length - 1){
                    return 0;
                } else {
                    return prevQuoteIndex + 1;
                }
            })
        }, slideDuration)
        
        //quoteState controls which button is showing (pause or play)
        setQuoteState(true);
    }

    //if the quote interval ref is active, clear it, set it to null
    //and change the pause/play button
    const stopQuoteInterval = () => {
        if(intervalRef.current){
            clearInterval(intervalRef.current);
            intervalRef.current = null;
            setQuoteState(false);
        }
    }

    // Start the quote interval count on page load
    // Clean-up function to reset the interval
    useEffect(() => {   
        startQuoteInterval();

        return () => stopQuoteInterval();
    }, [])

    // Create the quote card based on the index of the quotes array    
    const quote = (
            <div className="quote-card">
                <div className="quote">"{quoteArray[quoteIndex].quote}"</div>
                <div className="quote-attribute">{quoteArray[quoteIndex].author && `-${quoteArray[quoteIndex].author}, ${quoteArray[quoteIndex].title}`}</div>
            </div>
    )
    
    //algorithm for controlling bubble selection event for quotes
    //looks at the dataset.index value on the element clicked and sets that 
    //to the quote index, then restarts the quote interval
    const handleCardSelect = (event) => {
        if(event.target.id.includes("quote")){
            setQuoteIndex(+event.target.dataset.index);
        }

        if(intervalRef.current){
            stopQuoteInterval();
            startQuoteInterval();
        }
    }

    //creates clickable bubbles under the quote carousel for people to advance to a particular
    //quote
    const bubbles = quoteArray.map( (quote, index) => {
        return (
            <div id={`quote-${index}`} data-index={index} key={`quote-${index}`} className={`card-bubble ${quoteIndex === index ? "active" : null}`} onClick={handleCardSelect}></div>
        )
    })

    //Get brand-red css branding color
    const rootStyles = getComputedStyle(document.documentElement);
    const brandRed = rootStyles.getPropertyValue('--brand-red').trim();

    return(
        <div className="quotes darken-bg">
            <div className="quote-carousel">
                <div className="bg-card">
                    <h4>What People Are Saying</h4>
                    <hr className="separator" style={{ backgroundColor: "white"}}/>
                </div>
                {quote}
            </div>
            <div className="bubbles">
                {bubbles}
            </div>
            <div className="quote-controls">
                {quoteState === true ? 
                <button className="no-format" onClick={stopQuoteInterval}>
                    <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="20" cy="20" r="19" fill="white" stroke={brandRed} strokeWidth="2" />
                        <rect x="14" y="10" width="4" height="20" fill={brandRed} />
                        <rect x="22" y="10" width="4" height="20" fill={brandRed} />
                    </svg>
                </button>
                : 
                <button className="no-format" onClick={startQuoteInterval}>
                    <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="20" cy="20" r="19" fill={brandRed} stroke="white" strokeWidth="2" />
                        <polygon points="15,10 30,20 15,30" fill="white" />
                    </svg>
                </button>}
            </div>
        </div>
    )
}