import React from "react";
import { Link } from "react-router-dom";
import volunteerEventSlide from "../assets/event_slide_1.png";
import presentEventSlide from "../assets/event_slide_2.png";
import hostEventSlide from "../assets/event_slide_3.png";

export default function Events( { upcomingEvent } ) {

    // onTouchStart and onTouchEnd functions to handle mobile swiping and still populating info graphics over the pictures
    const handleTouchStart = (event) => {
        console.log(event.target.parentElement.lastChild.style.top = 0);
        document.querySelectorAll(`#${event.target.parentElement.id} .overlay-child`).forEach( child => {
            child.style.color = "rgba(255, 255, 255, 1)";
        })
    }

    const handleTouchEnd = (event) => {
        console.log(event.target.parentElement.lastChild.style.top = "100%");
        document.querySelectorAll(`#${event.target.parentElement.id} .overlay-child`).forEach( child => {
            child.style.color = "rgba(255, 255, 255, .0)";
        })
    }
    
    return (
        <div id="events" className="events">
            <div className="event-header">
                <h3 className="brand-red subtitle">Events</h3>
                <hr className="separator brand-red" />
                {upcomingEvent && <p className="brand-red prominent-text med-font event-highlight">Join us for our next event at Expedia HQ, where we will explore innovating the candidate experience!<br /><Link className="underline bold sm-font" to="https://www.eventbrite.com/e/innovating-the-candidate-experience-tickets-717959154157" target="_blank" rel="noreferrer">Click here to sign up now!</Link></p>}
                <p className="brand-red prominent-text">Events are custom-built for the Sourcing7+ community.  Our events range from hands-on workshops, to presentations, to expert panel discussions.</p>
                <p className="brand-red prominent-text">We've always kept events free to attend through the generosity of venues hosted by local area organizations, as well as our sponsors.</p>
            </div>
            <div className="event-card-display">
                <div id="volunteer" className="event-card no-select" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
                    <img src={volunteerEventSlide} alt="Two recruiters volunteering" />
                    <div id="volunteer-overlay" className="overlay gray-overlay no-select no-interaction" data-parent="volunteer" data-touched={true}>
                        <h3 className="small-subtitle overlay-child">Volunteer</h3>
                        <hr className="event-card-separator overlay-child" />
                        <p className="overlay-child">We always need help at events in the following areas:</p>
                        <ul className="volunteer-items overlay-child">
                            <li>Setup/Teardown</li>
                            <li>Guides to assist throughout the venue</li>
                            <li>Greeters to welcome attendees</li>
                            <li>Reception/Check-in</li>
                            <li>A/V Support</li>
                        </ul>
                    </div>
                </div>
                <div id="present" className="event-card" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>    
                    <img src={presentEventSlide} alt="A woman holding a microphone and presenting" />
                    <div className="overlay red-overlay no-select no-interaction">
                        <h3 className="small-subtitle overlay-child">Present</h3>
                        <hr className="event-card-separator overlay-child" />
                        <p className="overlay-child">You don't have to be Toastmaster certified! Our events are an opportunity to gain experience presenting in front of audiences - attendees are your peers and want you to succeed!</p>
                        
                        <p className="overlay-child">If you are passionate about a topic, we encourage you to share what you know.</p>
                    </div>
                </div>
                <div id="host" className="event-card" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>    
                    <img src={hostEventSlide} alt="A room of presentation attendees listening attentively" />
                    <div className="overlay gray-overlay no-select no-interaction">
                        <h3 className="small-subtitle overlay-child">Host</h3>
                        <hr className="event-card-separator overlay-child" />
                        <p className=" overlay-child">Our events remain free to attend due in part to the hospitality of our local venue hosts. If you have space to hold up to 100 seated attendees and a connected A/V system, we would love to hold a Sourcing 7 event with your organization.</p>
                    </div>
                </div>
            </div>
            <p className="information-text">These in-person events, held at various locations around the Seattle metro area, give you the opportunity to learn from multiple presenters and ask questions. Networking opportunities before and after presentations allow for you to meet new colleagues. Live events are presented either in panel, round-table, or Ignite-style presentation format. In addition, webinars led by local community members cover tactical topics like sourcing tools, techniques, and process.​</p>
        </div>
    )
}